import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import SectionDivider from "../components/Employers/SectionDivider";
import SummaryBlock from "../components/Home/SummaryBlock";
import Layout from "../components/layout";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Testimonial from "../components/Employers/Testimonial";
import LogoSection from "../components/Home/LogoSection";
import Accordion from "../components/Reusables/Accordion";
import styled from "styled-components";
import StatisticBox from "../components/Home/StatisticBlock";
import logo11 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/11.png";
import logo12 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/12.png";
import logo13 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/13.png";
import logo14 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/14.png";
import logo15 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/15.png";
import logo16 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/16.png";
import logo17 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/17.png";
import logo18 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/18.png";
import logo19 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/19.png";
import logo20 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/20.png";
import logo21 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/21.png";
import logo22 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/22.png";
import logo23 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/23.png";
import logo24 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/24.png";
import logo25 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/25.png";
import logo26 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/26.png";
import logo27 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/27.png";
import logo28 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/28.png";
import logo29 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/29.png";
import logo30 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/30.png";
import logo31 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/31.png";
import logo32 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/32.png";
import logo33 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/33.png";
import logo34 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/34.png";
import logo35 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/35.png";
import logo36 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/36.png";
import logo37 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/37.png";
import logo38 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/38.png";
import logo39 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/39.png";
import logo40 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/40.png";
import logo41 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/41.png";
import logo42 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/42.png";
import logo43 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/43.png";
import logo44 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/44.png";
import logo45 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/45.png";
import logo46 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/46.png";
import logo47 from "../images/UpReachPartnerLogos/PartnerEmployerLogos/47.png";

import logo57 from "../images/UpReachPartnerLogos/REALEmployerLogos/56.png";
import logo58 from "../images/UpReachPartnerLogos/REALEmployerLogos/57.png";
import logo59 from "../images/UpReachPartnerLogos/REALEmployerLogos/58.png";
import logo60 from "../images/UpReachPartnerLogos/REALEmployerLogos/59.png";
import logo61 from "../images/UpReachPartnerLogos/REALEmployerLogos/60.png";
import logo62 from "../images/UpReachPartnerLogos/REALEmployerLogos/61.png";
import logo63 from "../images/UpReachPartnerLogos/REALEmployerLogos/62.png";
import logo64 from "../images/UpReachPartnerLogos/REALEmployerLogos/63.png";
import logo65 from "../images/UpReachPartnerLogos/REALEmployerLogos/64.png";
import logo66 from "../images/UpReachPartnerLogos/REALEmployerLogos/65.png";

import hero from "../images/pageImages/Employers/Hero.jpg";
import testimonial from "../images/pageImages/Employers/Testimonial.jpg";
import employer from "../images/pageImages/Employers/employer.jpg";
import AwardAccordion from "../components/About/AwardAcordion";

import wtp1 from "../images/pageImages/Employers/WTP1.jpg";
import wtp2 from "../images/pageImages/Employers/WTP2.jpg";
import wtp3 from "../images/pageImages/Employers/WTP3.jpg";
import wtp4 from "../images/pageImages/Employers/WTP4.jpg";
import wtp5 from "../images/pageImages/Employers/WTP5.jpg";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .margin-top {
    margin-top: 80px;
  }

  .makeStyles-heading-2 {
    flex-basis: 100%;
  }

  .image-head-accordion {
    flex-basis: 33.33% !important;
  }
`;

const body = (
  <div>
    <p>
      Employers partner with us in a variety of ways. We are committed to help
      them in their mission for a more socially diverse organisation. Each
      partnership is bespoke and all of our partnerships are impact driven,
      which helps our partners attract more applications from high calibre
      students from less-advantaged backgrounds, with a very high success rate.
    </p>
    <p>
      We coordinate insight days, professional work experience and exclusive
      internship opportunities for upReach Associates, all in close
      collaboration with our employer partners. Employers can also use our
      contextualised data model, REALrating, to ensure they are selecting on
      potential not privilege.
    </p>
  </div>
);

const summary = [
  {
    title: "EMPLOYERS",
    description: body,
    image: `${employer}`,
    cta: "Get in touch",
    href: "David.Steel@upreach.org.uk?cc=partnerships@upreach.org.uk",
    hrefEmail: true,
  },
];

const csr = (
  <div>
    <p>
      Our partnerships with CSR are bespoke and varied, all of which enable
      upReach to support hundreds more students to improve their employability,
      whilst deepening the talent pool for the sector.
    </p>
    <p>
      In 2020-21, over 400 students will participate in our “Sector
      Springboards” thanks to sponsorship from organisations including Bank of
      America, Blackrock, Factset, Slaughter and May and the Diversity Project.
    </p>
    <p>
      Other CSR activities have helped facilitate our regional expansion, seen
      upReach become Charity of the Year, or seen us connect with existing
      employer outreach programmes, such as Allen & Overy's Smart Start
      Programme.
    </p>
  </div>
);

const rating = (
  <div>
    <p>
      REALrating is a research-backed contextual recruitment tool that takes
      into account fourteen indicators of socioeconomic disadvantage to provide
      contextualised A-level grades, indicating what an individual could have
      achieved in the absence of educational and socioeconomic disadvantage.
    </p>
    <p>
      By levelling the playing field and highlighting individuals who have
      achieved against the odds, the tool allows employers to identify the best
      people for the job, improve workplace diversity and champion social
      mobility.
    </p>
  </div>
);

const partnerWithUs = [
  {
    title: "MENTORING",
    description:
      "Mentoring boosts employee engagement. Our mentoring programme provides our Associates with an opportuntiy to personally develop and use their expertise to upskill students from less advantaged backgrounds. Mentors help students explore career options, expand their network and develop key employability skills, such as professionalism and commercial awareness.",
  },
  {
    title: "WORK EXPERIENCE",
    description:
      "upReach Associates typically have strong academic results and career potential, but many have had no professional work experience. Our partners provide an opportunity that is instrumental to students' career prospects, whilst being able to identify standout diverse talent that they can convert to full-time roles.",
  },
  {
    title: "CSR",
    description: csr,
  },
  {
    title: "ATTRACTION AND APPLICATION SUPPORT",
    description:
      "We promote our partner employers' early careers opportunities to our Associates, who, from our strong relationships with them, we know will be interested in and suitable for these opportunities. We partner with employers to understand their recruitment processes so we can provide comprehensive support with 1-to-1 coaching calls, an employer guide, online test training, mock interviews, employer-specific application overview video forums and skills workshops. ",
  },
  {
    title: "REALrating",
    description: rating,
  },
];

const carousel = [
  {
    description:
      "We believe that a fair society is one in which all people, irrespective of their background, can achieve their full potential. This is why we are proud to be a founding partner with upReach and over the last 5 years have worked together to offer [98] upReach Associates graduate jobs. We always enjoy watching the Associates improve their understanding and confidence with us as they progress through the programme with upReach; it is clear to see this support helps advance our commitment to improving social mobility.",
    image: `${testimonial}`,
    author: "- Assessment, Selection and Programmes Manager, Deloitte",
  },
];

const statistic = [
  {
    numbers: 5,
    description:
      "upReach applicants are typically five times more likely to make a successful application to one of our partner employers than the average applicant",
    color: "rgb(70, 79, 208)",
    suffix: "x",
  },
  {
    numbers: 950,
    description:
      " upReach Associates have made 950+ successful applications to our partners since 2015",
    color: "rgb(48, 69, 99)",
  },
  {
    numbers: 74,
    description:
      "upReach Associates have secured roles at 74 of the Times Top 100 Graduate Employers ",
    color: "rgb(255, 134, 0)",
  },
];

const logos = [
  { logo: `${logo11}` },
  { logo: `${logo12}` },
  { logo: `${logo13}` },
  { logo: `${logo14}` },
  { logo: `${logo15}` },
  { logo: `${logo16}` },
  { logo: `${logo17}` },
  { logo: `${logo18}` },
  { logo: `${logo19}` },
  { logo: `${logo20}` },
  { logo: `${logo21}` },
  { logo: `${logo22}` },
  { logo: `${logo23}` },
  { logo: `${logo24}` },
  { logo: `${logo25}` },
  { logo: `${logo26}` },
  { logo: `${logo27}` },
  { logo: `${logo28}` },
  { logo: `${logo29}` },
  { logo: `${logo30}` },
  { logo: `${logo31}` },
  { logo: `${logo32}` },
  { logo: `${logo33}` },
  { logo: `${logo34}` },
  { logo: `${logo35}` },
  { logo: `${logo36}` },
  { logo: `${logo37}` },
  { logo: `${logo38}` },
  { logo: `${logo39}` },
  { logo: `${logo40}` },
  { logo: `${logo41}` },
  { logo: `${logo42}` },
  { logo: `${logo43}` },
  { logo: `${logo44}` },
  { logo: `${logo45}` },
  { logo: `${logo46}` },
  { logo: `${logo47}` },
];
const RealLogos = [
  { logo: `${logo57}` },
  { logo: `${logo58}` },
  { logo: `${logo59}` },
  { logo: `${logo60}` },
  { logo: `${logo61}` },
  { logo: `${logo62}` },
  { logo: `${logo63}` },
  { logo: `${logo64}` },
  { logo: `${logo65}` },
  { logo: `${logo66}` },
];

const testimonialHeading = { title: "Hear from our Employers:" };

const logoSectionHeading = { title: "Trusted by 50+ Employers" };

const logoSectionHeadingFlag = { title: "OUR EMPLOYER PARTNERS INCLUDE" };

const logoRealRatingHeading = { title: "Our REALrating Partners include" };

const accordionHeading = { heading: "Ways to Partner with us" };

const AAASbody = (
  <div>
    <p>
      We promote our partner employers' early careers opportunities to our
      Associates, who, from our strong relationships with them, we know will be
      interested in and suitable for the opportunities.{" "}
    </p>
    <p>
      We partner with employers to understand their recruitment processes so we
      can provide comprehensive support with 1-to-1 coaching calls, an employer
      guide, online test training, mock interviews, employer-specific
      application overview video forums and skills workshops.
    </p>
  </div>
);

const accordion = [
  {
    image: `${wtp4}`,
    shortDescription: "ATTRACTION AND APPLICATION SUPPORT",
    description: AAASbody,
  },
  {
    image: `${wtp3}`,
    shortDescription: "CSR",
    description: csr,
  },
  {
    image: `${wtp1}`,
    shortDescription: "MENTORING",
    description:
      "Our mentoring programme provides employees at our partner employers with a two-way learning opportunity, which also gives them the chance to use their expertise to upskill students from less advantaged backgrounds. Mentors help students to explore career options, expand their network and develop key employability skills, such as professionalism and commercial awareness.",
  },
  {
    image: `${wtp2}`,
    shortDescription: "WORK EXPERIENCE",
    description:
      "upReach Associates typically have strong academic results and career potential, but many have had no professional work experience. Our partners provide an opportunity that is instrumental to students' career prospects, whilst being able to identify standout diverse talent that they can convert to full time roles.",
  },
  {
    image: `${wtp5}`,
    shortDescription: "REALrating (Contextual Recruitment)",
    description: rating,
  },
];

const heroImage = { hero: `${hero}` };

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Employers />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/employers-page`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};
const fetchStats = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stats`);
};

const Employers = ({ data }) => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const { data: pageData } = useQuery("employers", fetchData);
  const { data: heroImage } = useQuery("employerImages", fetchHeroImage);
  const { data: stats } = useQuery("employerStats", fetchStats);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
    if (stats !== undefined) {
      setStatsData(stats.data);
    }
  }, [pageData, heroImage, stats]);

  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showHeroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "EmployersPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}
        {newData && newData.showSummaryBlock && newData.SummaryBlock && (
          <SummaryBlock summary={newData.SummaryBlock} />
        )}
        {newData &&
          newData.showStatistics &&
          statsData &&
          statsData.length > 0 &&
          statsData.map(
            item =>
              item.slug.toLowerCase() === "Employers Page".toLowerCase() &&
              item.StudentStatistics.length > 0 && (
                <StatisticBox statistic={item.StudentStatistics} />
              )
          )}
        {newData &&
          newData.showTestimonial &&
          newData.Testimonial.length > 0 && (
            <Testimonial carousel={newData.Testimonial} />
          )}
        {/* <div className="container-fluid p-0">
        <Accordion
          accordionHeading={accordionHeading}
          accordion={partnerWithUs}
        />
      </div> */}

        {newData &&
          newData.showAwardAccordion &&
          newData.AwardAccordion.length > 0 && (
            <div className="container-fluid padding-left-8 padding-right-8 margin-top-100">
              <AwardAccordion accordion={newData.AwardAccordion} />
            </div>
          )}

        {newData && newData.showEmployerPartner && (
          <LogoSection
            logos={newData.employerPartner.logos}
            logoSectionHeading={newData.employerPartner}
          />
        )}
        {newData && newData.showProgrammeFunders && (
          <LogoSection
            logos={newData.programmeFunders.logos}
            logoSectionHeading={newData.programmeFunders}
          />
        )}
        {newData && newData.showRealrating && (
          <LogoSection
            logos={newData.realRating.logos}
            logoSectionHeading={newData.realRating}
          />
        )}
        {newData && newData.showSupportersLogos && (
          <LogoSection
            logos={newData.supportersLogos.logos}
            logoSectionHeading={newData.supportersLogos}
          />
        )}
      </StyledWrapper>
    </Layout>
  );
};
